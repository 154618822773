<template>
   <div id="simple-calendar-app">
      <div class="flex items-center w-full p-4 bg-primary text-white top-header-page" @click.stop="back()">
          <feather-icon icon="ChevronLeftIcon"  class="cursor-pointer mr-4" @click.stop="backHome()"></feather-icon>
          <h4 class="text-white">Atur Jadwal Kunjungan</h4>
      </div>
      
      <div class="vx-card fix-content mb-5">
        <calendar-view
          ref="calendar"
          :displayPeriodUom="calendarView"
          :show-date="showDate"
          :events="jadwalKunjungan"
          enableDragDrop
          :eventTop="windowWidth <= 400 ? '2rem' : '3rem'"
          eventBorderHeight="0px"
          eventContentHeight=".5rem"
          class="theme-default"
          @click-date="openAddNewEvent"
          @click-event="openViewEvent">
          <div slot="header" class="mb-8">
              <div class="vx-row no-gutter">
                <div class="vx-col w-full">
                  <div class="flex items-center justify-between">
                    <feather-icon
                        :icon="$vs.rtl ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
                        @click="updateMonth(-1)"
                        svgClasses="w-5 h-5 m-1"
                        class="cursor-pointer bg-primary text-white rounded-full" />

                    <span class="mx-3 text-xl font-medium whitespace-no-wrap">{{ showDate | month }}</span>

                    <feather-icon
                        :icon="$vs.rtl ? 'ChevronLeftIcon' : 'ChevronRightIcon'"
                        @click="updateMonth(1)"
                        svgClasses="w-5 h-5 m-1"
                        class="cursor-pointer bg-primary text-white rounded-full" />
                  </div>
                </div>
              </div>
          </div>
        </calendar-view>
      </div>

      <vx-card title-color="primary" title="Keterangan :">
        <div class="vx-row">
          <div class="vx-col w-1/2 mb-5 flex items-center">
            <vs-button color="primary" type="border" icon-pack="feather" icon="icon-archiv" class="mr-2"></vs-button>
            <p style="font-size: 12px;">Tanggal</p>
          </div>

          <div class="vx-col w-1/2 mb-5 flex items-center">
            <vs-button color="danger" type="filled" icon-pack="feather" icon="icon-archiv" class="mr-2"></vs-button>
            <p style="font-size: 12px;">Kuota Penuh</p>
          </div>

          <div class="vx-col w-1/2 mb-5 flex items-center">
            <vs-button color="success" type="filled" icon-pack="feather" icon="icon-archiv" class="mr-2"></vs-button>
            <p style="font-size: 12px;">Tanggal Terpilih</p>
          </div>

          <div class="vx-col w-1/2 mb-5 flex items-center">
            <vs-button color="warning" type="filled" icon-pack="feather" icon="icon-archiv" class="mr-2"></vs-button>
            <p style="font-size: 12px;">Hampir Penuh</p>
          </div>

          <vs-divider></vs-divider>
          
            <p class="px-5 mb-2">1. Mohon anda memastikan kehadiran anda pada tanggal terpilih.</p>
            <p class="px-5">2. Pastikan kelengkapan dokumen terpenuhi.</p>

        </div>
      </vx-card>

      <!-- ADD EVENT -->
      <vs-prompt
        class="calendar-event-dialog"
        title="Tambah Kunjungan"
        accept-text= "Berkunjung"
        @accept="addEvent"
        :is-valid="validForm"
        :active.sync="activePromptAddEvent">
        <div class="my-4">
          <small class="date-label">Tanggal Kunjungan</small>
          <datepicker :language="$vs.rtl ? langHe : langEn" name="start-date" v-model="startDate" :disabled="disabledFrom"></datepicker>
        </div>
        <!--
        <div class="my-4">
          <vs-select
          label="Pilih Waktu"
          class="w-full pb-5"
          v-model="kunjunganJam"
          >
            <vs-select-item v-for="(waktu, index) in jam" :value="waktu.value" :text="waktu.text" :key="index"/>
          </vs-select>
        </div>
        -->
      </vs-prompt>

      <!-- VIEW EVENT -->
      <vs-popup
         :title="`Permohonan ${dataPemohon.berkas_name}`"
         :active.sync="activePromptViewEvent">

         <div class="text-center">
            <h4 class="mb-4">{{dataJadwal.hari}}, {{dataJadwal.kunjungan_tanggal}}</h4>
            <p>Pukul</p>
            <h1 class="primary mb-4">{{dataJadwal.kunjungan_jam}}:00</h1>
            <h4 class="mb-4">BPN Kota Makassar</h4>

            <p class="">Pemohon : {{dataPemohon.nama}}</p>
            
            <img :src="getImage('/qrcode/'+dataJadwal.qrcode)" alt="" class="responsive p-base w-50" v-if="dataJadwal.qrcode"/>
            
            <p class="text-sm">- Pemohon wajib datang 5 menit sebelum jadwal antrian</p>
            
            <!--
            <vs-button color="primary" type="relief" class="mt-5 w-full" @click.stop="konfirmasi()" :disabled="dateKonfirmasi(dataJadwal.tanggal)"> KONFIRMASI KEDATANGAN </vs-button>
            -->
            
            <vs-button color="warning" type="relief" class="mt-5 w-full" @click.stop="openEditEvent()" :disabled="dateDisabled(dataJadwal.tanggal)"> UBAH JADWAL </vs-button>
            
            <vs-button color="danger" type="relief" class="mt-5 w-full" @click.stop="removeEvent()" :disabled="dateDisabled(dataJadwal.tanggal)"> HAPUS JADWAL </vs-button>
         </div>

      </vs-popup>

      <!-- EDIT EVENT -->
      <vs-prompt
        class="calendar-event-dialog"
        title="Edit Event"
        accept-text= "Update"
        button-cancel = "border"
        @accept="editEvent"
        :is-valid="validForm"
        :active.sync="activePromptEditEvent">

        <div class="my-4">
            <small class="date-label">Tanggal Kunjungan</small>
            <datepicker :language="$vs.rtl ? langHe : langEn" name="start-date" v-model="startDate" :disabled="disabledFrom"></datepicker>
        </div>
        <div class="my-4">
            <vs-select
            label="Pilih Waktu"
            class="w-full pb-5"
            v-model="kunjunganJam"
            >
              <vs-select-item v-for="(waktu, index) in jam" :value="waktu.value" :text="waktu.text" :key="index"/>
            </vs-select>
        </div>

      </vs-prompt>

      <!-- Nomor Urut -->
      <vs-popup
         title="Nomor Urut Panggilan"
         :active.sync="activePromptUrut">

         <div class="text-center">
            <h1 class="mb-4" style="font-size:xxx-large">{{nomorUrut}}</h1>
            <p class="text-sm">Perhatikan nomor urut anda ketika dipanggil</p>
         </div>

      </vs-popup>

   </div>
</template>

<script>
import { CalendarView, CalendarViewHeader } from 'vue-simple-calendar'
//import moduleCalendar from '@/stores/calendar/moduleCalendar.js'
require('vue-simple-calendar/static/css/default.css')

import Datepicker from 'vuejs-datepicker'
import { en, he } from 'vuejs-datepicker/src/locale'

export default {
  components: {
    CalendarView,
    CalendarViewHeader,
    Datepicker
  },
  data () {
    return {
      showDate: new Date(),
      disabledFrom: false,
      id: 0,
      title: '',
      startDate: '',
      endDate: '',
      labelLocal: 'none',

      langHe: he,
      langEn: en,

      url: '',
      calendarView: 'month',

      dataJadwal: {},
      dataPemohon: {},

      activePromptAddEvent: false,
      activePromptViewEvent: false,
      activePromptEditEvent: false,

      calendarViewTypes: [
        {
          label: 'Month',
          val: 'month'
        },
        {
          label: 'Week',
          val: 'week'
        },
        {
          label: 'Year',
          val: 'year'
        }
      ],

      jam : [
        { value:"0", text:"Pilih" },
        { value:"08", text:"08:00" },
        { value:"09", text:"09:00" },
        { value:"10", text:"10:00" },
        { value:"11", text:"11:00" },
        { value:"13", text:"13:00" },
        { value:"14", text:"14:00" },
        { value:"15", text:"15:00" },
        { value:"16", text:"16:00" }
      ],
      kunjunganJam: '0',
      dataID: 0,
      dataTitle: 0,
      dataNama: '',

      dataKedatangan: {},
      nomorUrut: 0,
      activePromptUrut: false
    }
  },
  computed: {
   /*
    simpleCalendarEvents () {
      //alert(this.$store.state.calendar.events[0].startDate)
      //return this.$store.state.calendar.events
    },
    */
    jadwalKunjungan () {
      //const { id, task_title, kunjungan_tanggal, kunjungan_jam,hari } = JSON.parse(JSON.stringify(this.$route.params.item))
      const kunjungan = []
      for (const element of this.$store.state.jadwal_user) {
        let obj = {
          id: element.id,
          title: element.parent.berkas_name,
          startDate: new Date(element.tanggal),
          endDate: new Date(element.tanggal),
          url: '',
          classes: 'event-success',
          label: ''
        }
        kunjungan.push(obj)
      }
      return kunjungan
    },
    validForm () {
      return this.startDate !== ''
      // && this.kunjunganJam !== '0'
    },
    disabledDatesTo () {
      return { to: new Date(this.startDate) }
    },
    disabledDatesFrom () {
      return { from: new Date(this.endDate) }
    },
    /*
    calendarLabels () {
      //return this.$store.state.calendar.eventLabels
    },
    */
    labelColor () {
      return (label) => {
        if      (label === 'business') return 'success'
        else if (label === 'work')     return 'warning'
        else if (label === 'personal') return 'danger'
        else if (label === 'none')     return 'primary'
      }
    },
    windowWidth () {
      return this.$store.state.windowWidth
    }
  },
  mounted () {
    /*
    if (this.$route.params.data.nama) {
        this.dataNama = this.$route.params.data.nama
        this.dataNomor = this.$route.params.data.nomor
    }
    */
    const { id, nama, task_title } = JSON.parse(JSON.stringify(this.$route.params.submit))
    this.dataID = id
    this.dataTitle = task_title
    this.dataNama = nama
    
  },
  methods: {
    back () {
      if (this.$route.params.item) {
        let item = this.$route.params.item
        let submit = this.$route.params.submit
        this.$router.replace({ 
            name: 'LayananItem',
            params: { item, submit } 
         });
      } else {
        this.$router.push({ 
          name: 'Home'
        });
      }
    },
    addEvent () {
      this.$vs.loading()
      let formData = new FormData()
      
      formData.set('title', 'Antrian '+ this.dataTitle)
      formData.set('status', 'publish')
      formData.set('comment_status', 'open')
      formData.set('password', null)
      formData.set('parent', this.dataID)
      formData.set('type', 'antrian_app')
      formData.set('comment_count', 0)
      
      formData.set('get_parent', 'true')
      
      let stringDate = String(this.formatDate(this.startDate))
      /*
        let stringJam = String(this.dataJadwal.kunjungan_jam)
        +stringJam
      */
      let gabung = stringDate
      let reg = gabung.replaceAll('-', '')
      formData.set('kedatangan_reg', 'antrian_app'+reg)

      let meta = {
        pemohon: this.dataNama
      }
      formData.set('meta', JSON.stringify(meta))
      
      this.$store.dispatch('kedatanganAdd', formData)
      .then((response) => {
        this.$vs.loading.close()
        this.dataKedatangan = response.data
        let urut = parseInt(this.dataKedatangan.kedatangan_urut)
        this.nomorUrut = ('00' + urut).slice(-3)
        this.activePromptViewEvent = false
        this.activePromptUrut = true
        this.$vs.notify({
          title: 'Berhasil',
          color  : 'success',
          text  : response.message,
        })
      })
      .catch(error => {
        this.$vs.loading.close()
        let {data} = error.response
        this.$vs.notify({
          title: 'Error',
          text: data.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    },
    /*
    addEvent () {
      this.$vs.loading()
      let formData = new FormData()

      //formData.set('id', this.dataTaskID)
      formData.set('title', this.formatDateIndo(this.startDate)+' '+this.dataTitle)
      formData.set('status', 'publish')
      formData.set('comment_status', 'open')
      formData.set('password', null)
      formData.set('parent', this.dataID)
      formData.set('type', 'jadwal')
      formData.set('comment_count', 0)

      let meta = {
        kunjungan_tanggal: this.formatDateIndo(this.startDate),
        kunjungan_jam: this.kunjunganJam,
        hari: this.getHari(this.startDate),
        tanggal: this.formatDate(this.startDate)
      }
      formData.set('meta', JSON.stringify(meta))
      
      formData.set('qrcode', 'true')
      formData.set('get_parent', 'true')
      this.$store.dispatch('jadwalAdd', formData)
      .then((response) => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Berhasil',
            color  : 'success',
            text  : response.message,
          })
          //this.dataData = response.data.data
          //this.toJadwal()
      })
      .catch(error => {
          this.$vs.loading.close()
          let {data} = error.response
          this.$vs.notify({
            title: 'Error',
            text: data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
      })
    },
    */
    formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      return [year, month, day].join('-');
    },
    getHari(date) {
      var today = new Date(date).getDay();
      var days = ['Minggu','Senin','Selasa','Rabu','Kamis','Jumat','Sabtu'];
      return days[today];
    },
    formatDateIndo(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      return [day, month, year].join('-');
    },
    updateMonth (val) {
      this.showDate = this.$refs.calendar.getIncrementedPeriod(val)
    },
    clearFields () {
      this.title = this.endDate = this.url = ''
      this.id = 0
      this.labelLocal = 'none'
    },
    addNewEventDialog (date) {
      this.clearFields()
      this.startDate = date
      this.endDate = date
      this.activePromptAddEvent = true
    },
    openAddNewEvent (date) {
      this.disabledFrom = true
      var d = new Date()
      if (this.dateCalc(date) >= this.dateCalc(d)) {
        this.addNewEventDialog(date)
      } else {
        this.$vs.notify({
          title: '',
          text: 'Tanggal sudah lewat',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
    },
    openViewEvent (event) {
        const e = this.$store.state.jadwal_user.find((j) => j.id == event.id)
        this.dataJadwal = e
        this.dataPemohon = e.parent
        this.activePromptViewEvent = true
    },
    openEditEvent () {
      this.startDate = this.dataJadwal.tanggal
      this.kunjunganJam = this.dataJadwal.kunjungan_jam
      this.dataID = this.dataJadwal.id
      this.dataTitle = this.dataJadwal.task_title
      this.activePromptViewEvent = false
      this.activePromptEditEvent = true
    },
    editEvent () {
      this.$vs.loading()
      let formData = new FormData()

      formData.set('id', this.dataJadwal.id)
      formData.set('title', this.dataJadwal.task_title)
      formData.set('status', 'publish')
      formData.set('comment_status', 'open')
      formData.set('password', null)
      formData.set('parent', this.dataJadwal.task_parent)
      formData.set('type', 'jadwal')
      formData.set('comment_count', 0)
      
      formData.set('get_parent', 'true')
      let meta = {
        kunjungan_tanggal: this.formatDateIndo(this.startDate),
        kunjungan_jam: this.kunjunganJam,
        hari: this.getHari(this.startDate),
        tanggal: this.formatDate(this.startDate)
      }
      formData.set('meta', JSON.stringify(meta))
      
      formData.set('qrcode', 'true')
      this.$store.dispatch('jadwalUpdate', formData)
      .then((response) => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Berhasil',
            color  : 'success',
            text  : response.message,
          })
      })
      .catch(error => {
          this.$vs.loading.close()
          let {data} = error.response
          this.$vs.notify({
            title: 'Error',
            text: data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
      })
    },
    removeEvent () {
      this.$store.dispatch('jadwalRemove', this.dataJadwal.id)
      this.activePromptViewEvent = false
    },
    dateCalc(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
      return [year, month, day].join('');
    },
    dateDisabled (date) {
      var d = new Date()
      if (this.dateCalc(date) >= this.dateCalc(d)) {
        return false
      } else {
        return true
      }
    },
    dateKonfirmasi (date) {
      var d = new Date()
      if (this.dateCalc(date) == this.dateCalc(d)) {
        return false
      } else {
        return true
      }
    },
    konfirmasi () {
      this.$vs.loading()
      let formData = new FormData()

      formData.set('id', this.dataJadwal.id)
      formData.set('title', 'Antrian '+ this.dataJadwal.task_title)
      formData.set('status', 'publish')
      formData.set('comment_status', 'open')
      formData.set('password', null)
      formData.set('parent', this.dataJadwal.parent.id)
      formData.set('type', 'antrian')
      formData.set('comment_count', 0)
      
      formData.set('get_parent', 'true')
      
      let stringDate = String(this.formatDate(this.dataJadwal.tanggal))
      let stringJam = String(this.dataJadwal.kunjungan_jam)
      let gabung = stringDate+stringJam
      let reg = gabung.replaceAll('-', '')
      formData.set('kedatangan_reg', reg)

      let meta = {
        pemohon: this.dataJadwal.parent.nama
      }
      formData.set('meta', JSON.stringify(meta))
      
      this.$store.dispatch('kedatanganAdd', formData)
      .then((response) => {
        this.$vs.loading.close()
        this.dataKedatangan = response.data
        let urut = parseInt(this.dataKedatangan.kedatangan_urut)
        this.nomorUrut = ('00' + urut).slice(-3)
        this.activePromptViewEvent = false
        this.activePromptUrut = true
        this.$vs.notify({
          title: 'Berhasil',
          color  : 'success',
          text  : response.message,
        })
      })
      .catch(error => {
        this.$vs.loading.close()
        let {data} = error.response
        this.$vs.notify({
          title: 'Error',
          text: data.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    }
  },
  created () {
    this.$store.commit('navbarUpdateStatus', true)
  },
  beforeDestroy () {
    //this.$store.unregisterModule('calendar')
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/calendar-jadwal.scss";
.fix-content {
   height: 28rem;
}
.includeIconOnly {
  width: 30px!important;
  height: 30px!important;
}
</style>
